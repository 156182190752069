import '../components/polyfills';

import * as React from 'react';
import App from 'next/app';
import withRedux from 'next-redux-wrapper';
import { Provider } from 'react-redux';

// import * as Sentry from '@sentry/nextjs';

// css

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

import '../components/LayoutApp.css';
import '../components/LayoutLibrary.css';
import './custom.css';

import makeStore from '../store';

const withStore = withRedux(makeStore);

// const isProductionBuild = process.env.NODE_ENV === 'production';

class MyApp extends App {
  componentDidCatch(error, errorInfo) {
    // if (isProductionBuild) {
    //   Sentry.withScope((scope) => {
    //     Object.keys(errorInfo).forEach((key) => {
    //       scope.setExtra(key, errorInfo[key]);
    //     });

    //     Sentry.captureException(error);
    //   });
    // }

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps, store } = this.props;

    return (
      <Provider store={store}>
        <Component {...pageProps} />
      </Provider>
    );
  }
}

export default withStore(MyApp);
