import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import reduxPromiseMiddleware from 'redux-promise-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';

import realtimeEnhancer from './realtime';

import rootReducer from './rootReducer';

const composeEnhancers =
  // process.env.NODE_ENV === 'production'
  //   ? compose
  // :
  composeWithDevTools({
    name: 'Backdrop for Outlook',
    trace: true,
    traceLimit: 25,
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
  });

const enhancers = composeEnhancers(
  realtimeEnhancer,
  applyMiddleware(reduxPromiseMiddleware, reduxThunk)
);

function configureStore(initialState = {}, { isServer, req }) {
  const store = createStore(rootReducer, initialState, enhancers);

  if (module.hot) {
    module.hot.accept('./rootReducer', () => {
      const nextReducer = require('./rootReducer').default;
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

export default configureStore;
