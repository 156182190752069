import { combineReducers } from 'redux';

import authUser from '../state/authUser';
import notification from '../state/notification';
import spaceView from '../state/spaceView';
import wizard from '../state/wizard';
import libraryView from '../state/libraryView';

export default combineReducers({
  authUser,
  notification,
  spaceView,
  wizard,
  libraryView,
});
