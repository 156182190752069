// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const APP_ENVIRONMENT = process.env.APP_ENVIRONMENT || 'local';

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://e843308463614710bb81d0cfef1acbcc@o399506.ingest.sentry.io/6135414',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: APP_ENVIRONMENT,
  denyUrls: [
    'moz-extension://*',
    'chrome-extension://*',
    'www.googletagmanager.com',
    'static.hotjar.com',
    'widget.intercom.io',
    'js.intercomcdn.com',
    'connect.facebook.net',
    'app.termly.io',
  ],
});
